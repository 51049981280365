import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from './Components/Home';

const Routes = () => (
    <Router>
        <Route exact path="/imageid=:imageid" component={Home} />
    </Router>
);

export default Routes;