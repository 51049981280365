import React from 'react'
import { Grid, Box, Text, List, ListItem, ListIcon, ButtonGroup } from "@chakra-ui/core";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useState, useEffect, useRef } from "react";
import { FormControl, FormLabel, Select, Tab, TabList, Tabs, Input, TabPanel, TabPanels } from "@chakra-ui/core";
import { Image, Button } from "@chakra-ui/core";
import { useHistory } from "react-router-dom";
import { Radio, RadioGroup } from "@chakra-ui/core";
import * as THREE from "three";
import { createCuboid } from "./CreateCuboid";
// import OrbitalControls from "three-orbitcontrols";
// import TransformControls from "three-transform-ctrls";
import { render } from '@testing-library/react';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

const GET_DATA = gql`
    query MyQuery ($id: Int!) {
        pose_threejs(where: {id: {_eq: $id}}) {
            id
            isLabelled
            person_id
            rotation
        }
    }
`;

const UPDATE_ORDER = gql`
    mutation MyMutation($id: Int!, $isLabelled: String!, $rotation: Int!){
        update_pose_threejs(where: {id: {_eq: $id}}, _set: {isLabelled: $isLabelled, rotation: $rotation}) {
            affected_rows
        }
    }
`;

function getMousePos(canvas, evt) {
    const rect = canvas.current.getBoundingClientRect();
    return {
      x: evt.clientX - rect.x,
      y: evt.clientY - rect.y,
    };
  }

function Home(props) {
    const [condition, setCondition] = useState("bad");
    const [isLabelled, setIsLabelled] = useState("Not Labelled");
    const [rotationY, setRotationY] = useState(0);
    const [translationX, settranslationX] = useState(165);
    const [translationY, settranslationY] = useState(-150);
    const [obj, setObj] = useState();
    const [setRotation, changeSetRotation] = useState(false);

    const mainContainer = useRef(null);
    const renderer = useRef();
    const scene = useRef();
    const camera = useRef();
    const backImage = useRef();
    const controls = useRef();
    const [labelingReady, setLabelingReady] = useState(false);

    let [_, setForceRefresh] = useState(5.5);

    const forceRefresh = () => {
        setForceRefresh(Math.random());
    };

    const history = useHistory();

    const [getImageUrl, { data: data }] = useLazyQuery(GET_DATA);
    const [update_data, { error: formSubmitError, loading: formLoading }] = useMutation(UPDATE_ORDER, {
        onError(error) {
            alert(`The form did not submit. Please check your internet connection. \n For Technical Purpose: ${error}`)
        },
        onCompleted(data) {
            if (data) {
                console.log("updated...")
                let nextid = parseInt(props.match.params.imageid) + 1;
                history.push(`/imageid=${nextid}`)
                window.location.reload()
            }
        }
    });

    function convertRadiansToDegrees(radians) {
        return (radians * 180) / Math.PI;
    };

    function convertDegreesToRadians(degrees) {
        return (degrees * Math.PI) / 180;
    };

    useEffect(() => {
        if (props.match.params.imageid) {
            console.log("props.match.params.imageid: ", props.match.params.imageid)
            getImageUrl({
                variables: {
                    id: parseInt(props.match.params.imageid)
                }
            })
        }
    }, [props.match.params.imageid])

    const refreshScene = () => {
        if (renderer.current && scene.current) {
            renderer.current.render(scene.current, camera.current);
        }
    };
    const changeImage = (link) => {
        // setUrl(link)
        // alert(link)
        if (backImage.current && renderer.current) {
            let loader = new THREE.TextureLoader();
            loader.load(link, texture => {
                console.log("we are loading the textures");
                backImage.current.material = new THREE.MeshBasicMaterial({
                    map: texture
                });
                refreshScene();
            });
        } else {
            console.log("backimage not there")
        }
    };

    useEffect(() => {
        console.log("data: ", data)
        if (data?.pose_threejs?.length > 0) {
            setIsLabelled(data?.pose_threejs[0].isLabelled)
            setRotationY(data?.pose_threejs[0]?.rotation)
            let link = `https://storage.cloud.google.com/labelling-tools-data/pose_tool_person_images/${data?.pose_threejs[0]?.person_id}.png`
            if(data?.pose_threejs[0]?.id > 15859){
                link = `https://storage.cloud.google.com/labelling-tools-data/person_images_combined_white_padding/${data?.pose_threejs[0]?.person_id}.png`
            }
            changeImage(linkChange(link));
            refreshScene()
            console.log('data comes in')
        } else {
            console.log("empty data")
        }
    }, [data])

    const linkChange = (link) => {
        if (link === undefined) {
            return undefined
        }
        else {
            return `https://storage.googleapis.com/download/storage/v1/b/${link.split('/')[3]}/o/${link.substring((34 + link.split('/')[3].length)).replace(/[/]/g, '%2F')}?alt=media`
        }
    }

    const onInputChange = (e) => {
        setCondition(e.target.value)
    }

    const handleNext = () => {
        let nextid = parseInt(props.match.params.imageid) + 1;
        history.push(`/imageid=${nextid}`)
        window.location.reload()
    }
    useEffect(() => {
        if (mainContainer.current && renderer.current) {
            console.log('maincontainer')
            mainContainer.current.appendChild(renderer.current.domElement);
            renderer.current.render(scene.current, camera.current);
        }
    }, [mainContainer.current]);

    useEffect(() => {
        // alert('re rendering the main component')
        console.log("in the main container.current");
        if (mainContainer && mainContainer.current) {
            console.log("inside inside the main container");
            const width = 512;
            const height = 512;
            renderer.current = new THREE.WebGLRenderer({ antialias: true });
            scene.current = new THREE.Scene();
            camera.current = new THREE.OrthographicCamera(
                -width * 0.55,
                width * 0.55,
                height * 0.55,
                -height * 0.55
                // -50,50,50-50
            );
            camera.current.position.z = 1000;
            renderer.current.setSize(width, height);

            let geometry = new THREE.PlaneGeometry(width, height);
            let material = new THREE.MeshBasicMaterial();
            backImage.current = new THREE.Mesh(geometry, material);
            backImage.current.position.z = -1000;
            scene.current.add(backImage.current);
            // const axesHelper = new THREE.AxesHelper(100);
            // scene.current.add(axesHelper);
            let stacy_txt = new THREE.TextureLoader().load('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1376484/stacy.jpg');

            stacy_txt.flipY = false; // we flip the texture so that its the right way up

            const stacy_mtl = new THREE.MeshPhongMaterial({
                map: stacy_txt,
                color: 0xffffff,
                skinning: true
            });

            const MODEL_PATH = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/1376484/stacy_lightweight.glb';
            var loader = new GLTFLoader();
            loader.load(
                MODEL_PATH,
                function (gltf) {
                    // A lot is going to happen here

                    let model = gltf.scene;
                    console.log("gltf: ", gltf)
                    let fileAnimations = gltf.animations;
                    model.scale.set(200, 200, 200);
                    model.position.x = 165;
                    model.position.y = -150;
                    model.traverse(o => {
                        if (o.isMesh) {
                            // alert('is mesh true')
                            o.castShadow = true;
                            o.receiveShadow = true;
                            o.material = stacy_mtl; // Add this line
                        }
                    });
                    console.log("model: ", model)
                    var light = new THREE.DirectionalLight(0xffffff);
                    light.position.set(0, 1, 1).normalize();
                    scene.current.add(light);

                    scene.current.add(model);
                    setObj(model);
                    renderer.current.render(scene.current, camera.current);
                    setLabelingReady(true);
                    // refreshScene()
                    changeSetRotation(true)
                },
                undefined, // We don't need this function
                function (error) {
                    alert('err')
                    console.error(error);
                }
            );

            // let cuboid = createCuboid(0x00ff00, 20, 50, 70);
            // // cuboid.rotation.y = convertDegreesToRadians(rotationY);
            // setObj(cuboid);
            // scene.current.add(cuboid);

            // renderer.current.render(scene.current, camera.current);
            // setLabelingReady(true);
        }
    }, []);

    useEffect(() => {
        if (obj) {
            // console.log('here', rotationY, obj)
            obj.rotation.y = convertDegreesToRadians(rotationY);
            obj.position.x = translationX;
            obj.position.y = translationY;
            refreshScene()
        }
    }, [rotationY, translationX, translationY, setRotation])

    useEffect(() => {
        // if (camera.current && renderer.current.domElement) {
        //     // alert('here')
        //     controls.current = new TransformControls(camera.current, renderer.current.domElement);
        //     controls.current.addEventListener('change', refreshScene());

        //     // try {
        //     //     controls.current.attach(obj);
        //     // } catch (err) {
        //     //     console.log(err);
        //     // }
        //     let orbitControls = new OrbitalControls(
        //         camera.current,
        //         renderer.current.domElement
        //     );
        //     orbitControls.enableRotate = false;
        //     orbitControls.addEventListener("change", () => {
        //         refreshScene();
        //     });
        //     controls.current.addEventListener("mouseDown", function () {
        //         orbitControls.enabled = false;
        //     });
        //     controls.current.addEventListener("mouseUp", function () {
        //         setTimeout(() => {
        //             orbitControls.enabled = true;
        //         }, 200);
        //     });
        //     controls.current.scope = "global";
        //     controls.current.setSpace("world");
        //     controls.current.setMode("translate");

        //     scene.current.add(controls.current);
        //     refreshScene()
        // }
    }, [camera.current, renderer.current])

    useEffect(() => {
        mainContainer.current.addEventListener("wheel", (evt)=>{
            console.log(evt, evt.deltaY, rotationY)
            if(evt.deltaY > 0){
                setRotationY((rotationY) => rotationY+5)
            }else{
                setRotationY((rotationY) => rotationY-5)
            }
        }, { passive: false })
    }, [])

    const handleSubmit = () => {
        console.log("updating db")
        // alert(condition)
        update_data({
            variables: {
                id: parseInt(props.match.params.imageid),
                isLabelled: "Labelled",
                rotation: rotationY,
            }
        })
    }

    const capturePoint = (evt) => {
        const mousePos = getMousePos(mainContainer, evt);
        console.log(mousePos)
        settranslationX(mousePos.x - 260)
        settranslationY(- (mousePos.y - 70))
    }
    return (
        <div>
            <div style={{ marginTop: "50px" }}>
                <Grid templateColumns="1fr 1fr" gap={2}>
                    {/* <div /> */}
                    <div ref={mainContainer} onClick={capturePoint}/>

                    {/* <Box textAlign="center" margin="auto">
                        Person image
                        <br />
                        <br />
                        <Image
                            width="300px"
                            src={data?.pose_threejs[0]?.map_person.image_url}
                            alt="No image in database"
                            id="storage_image"
                        />
                    </Box> */}
                    <Box style={{ textAlign: "left", marginRight: "150px" }}>
                        <br />
                        <br />
                        <br />
                        {/* <br />
                        <Text fontSize="md">Mark image as bad in </Text>

                        <List as="ol" styleType="decimal">
                            <ListItem>cases where coat is covering too much</ListItem>
                            <ListItem>cases where there is limited view of person images (closeup)</ListItem>
                            <ListItem>side views/weird views</ListItem>
                        </List> */}
                        {/* <br /> */}

                    This item is <b> {isLabelled}</b>

                        <br />
                        <br />
                        Enter rotation
                        <br />
                        <br />
                        <Input placeholder="Rotation in y" type="number" value={rotationY} onChange={(e) => setRotationY(e.target.value)} />
                        {/* <Button>+</Button> */}
                        <br />
                        <br />
                        Enter position in x
                        <br />
                        <br />
                        <Input placeholder="Translation in x" type="number" value={translationX} onChange={(e) => settranslationX(e.target.value)} />

                        <br />
                        <br />
                        Enter position in y
                        <br />
                        <br />
                        <Input placeholder="Translation in y" type="number" value={translationY} onChange={(e) => settranslationY(e.target.value)} />


                        <form>
                            <ButtonGroup spacing={4}>
                                {/* <Button onClick={changeSetRotation(!setRotation)}>
                                    Refresh
                                </Button> */}
                                <Button
                                    mt={4}
                                    variantColor="green"
                                    onClick={handleSubmit}
                                    className="margin--10px"
                                >
                                    {formLoading ? "Submiting" : "Submit"}
                                    {/* Submit */}
                                </Button>

                                <Button mt={4} variantColor="blue" onClick={handleNext} >Next</Button>
                            </ButtonGroup>
                        </form>
                    </Box>
                </Grid>

            </div>
        </div>
    )
}

export default Home
